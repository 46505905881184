import { Address, ChargeItemDefinition, Coverage } from "fhir"
import { Formik } from "formik"
import { classNames } from "primereact/utils"
import { useState } from "react"

import { AddFieldArrayItemButton, BillingField, Button } from "commons"
import { BILLING_TYPES_CODES } from "data"

import { useCPOERequestsContext } from "../hooks"
import { ACTION_GROUP_CODES, ActionGroupCode, CpoeRequest } from "../types"
import { CheckoutItemsSection } from "./CheckoutItemsSection"
import { LaboratoryOrderListItem } from "./LaboratoryOrderListItem"
import { OrderDiscountListItem } from "./OrderDiscountListItem"
import { OrderFeeListItem } from "./OrderFeeListItem"
import { OrdersShippingAddress } from "./OrdersShippingAddress"

const CheckoutSection = ({
  shippingMethods,
  discounts,
  coverage,
  handleSelectCoverage,
  requests,
  sectionRequestsType,
  showShippingMethods,
  showBillingTypes,
  selectedShippingMethod,
  handleSelectShippingMethod,
  selectedDiscount,
  handleSelectDiscount,
  totalItemsCost,
  hasInsuranceLabRequest,
  labsShippingAddress,
  nutrasShippingAddress,
  className,
}: Props) => {
  const { isProcessingActions } = useCPOERequestsContext()
  const [showDiscount, setShowDiscount] = useState(!!selectedDiscount)
  const [showPharmaShippingMethod, setShowPharmaShippingMethod] = useState(!!selectedShippingMethod)

  const initialBillingType = coverage?.type?.coding?.[0].code ?? BILLING_TYPES_CODES.BILL_PATIENT
  const filteredRequests = requests.filter((req) => req.type === sectionRequestsType)
  const showShippingAddress =
    sectionRequestsType === ACTION_GROUP_CODES.NUTRA ||
    (sectionRequestsType === ACTION_GROUP_CODES.LAB && !!hasInsuranceLabRequest)
  const isPharmaSection = sectionRequestsType === ACTION_GROUP_CODES.PHARMA
  const allowRemoveShippingMethod = showShippingMethods && selectedShippingMethod && isPharmaSection

  if (!filteredRequests.length) return null

  return (
    <div className={classNames("pl-3", className)}>
      <div className="space-y-6">
        {sectionRequestsType !== ACTION_GROUP_CODES.LAB && (
          <CheckoutItemsSection readonly requests={filteredRequests} sectionRequestsType={sectionRequestsType} />
        )}
        {(!!shippingMethods?.length || !!discounts?.length || showBillingTypes || showShippingAddress) && (
          <>
            {showShippingAddress && (
              <OrdersShippingAddress
                className="pl-4"
                showMedsAddress={sectionRequestsType === ACTION_GROUP_CODES.NUTRA}
                showLabsAddress={sectionRequestsType === ACTION_GROUP_CODES.LAB && !!hasInsuranceLabRequest}
                labsShippingAddress={labsShippingAddress}
                nutrasShippingAddress={nutrasShippingAddress}
              />
            )}
            {!!shippingMethods?.length && (
              <div className="flex flex-col pl-4">
                <div className="flex justify-between items-center h-8">
                  <h4 className="text-sm font-medium inline-flex justify-between w-full items-center">
                    {sectionRequestsType === ACTION_GROUP_CODES.PHARMA ? "Additional Services" : "Shipping methods"}
                    <hr className="border flex-1 ml-3" />
                  </h4>
                  {allowRemoveShippingMethod && (
                    <Button
                      buttonStyle="text"
                      label="Remove"
                      onClick={() => {
                        if (selectedShippingMethod) {
                          handleSelectShippingMethod?.(selectedShippingMethod, sectionRequestsType)
                          setShowPharmaShippingMethod(false)
                        }
                      }}
                    />
                  )}
                </div>
                {showShippingMethods ? (
                  isPharmaSection && !showPharmaShippingMethod ? (
                    <AddFieldArrayItemButton label="Add service" onClick={() => setShowPharmaShippingMethod(true)} />
                  ) : (
                    <div className="pl-1 my-3">
                      {shippingMethods.map((cid, index) => (
                        <OrderFeeListItem
                          key={cid.id ?? index}
                          cid={cid}
                          selectCid={() => handleSelectShippingMethod?.(cid, sectionRequestsType)}
                          selected={selectedShippingMethod?.id === cid.id}
                          totalItemsCost={totalItemsCost ?? 0}
                        />
                      ))}
                    </div>
                  )
                ) : (
                  <p className="text-sm">
                    {sectionRequestsType === ACTION_GROUP_CODES.PHARMA
                      ? "No additional services"
                      : "No shipping methods"}
                  </p>
                )}
              </div>
            )}
            {!!discounts?.length && (
              <div className="flex flex-col pl-4">
                <div className="flex justify-between items-center h-8">
                  <h4 className="text-sm font-medium inline-flex justify-between w-full items-center">
                    Discounts
                    <hr className="border flex-1 ml-3" />
                  </h4>
                  {showDiscount && (
                    <Button
                      buttonStyle="text"
                      label="Remove"
                      onClick={() => {
                        selectedDiscount && handleSelectDiscount?.(selectedDiscount, sectionRequestsType)
                        setShowDiscount(false)
                      }}
                    />
                  )}
                </div>

                <div className="pl-1">
                  {showDiscount ? (
                    discounts.map((cid, index) => (
                      <OrderDiscountListItem
                        key={cid.id ?? index}
                        discount={cid}
                        selectCid={() => handleSelectDiscount?.(cid, sectionRequestsType)}
                        selected={selectedDiscount?.id === cid.id}
                      />
                    ))
                  ) : (
                    <AddFieldArrayItemButton label="Add discount" onClick={() => setShowDiscount(true)} />
                  )}
                </div>
              </div>
            )}
            {showBillingTypes && (
              <div className="flex flex-col pl-4 divide-y">
                {sectionRequestsType === ACTION_GROUP_CODES.LAB ? (
                  <>
                    {filteredRequests
                      .toSorted((a) => (a.laboratoryData?.billingType === BILLING_TYPES_CODES.INSURANCE ? 1 : -1))
                      .map((item, index) => (
                        <Formik
                          key={item.resource.resource?.id ?? index}
                          initialValues={{ billingType: item?.laboratoryData?.billingType, insurance: undefined }}
                          onSubmit={() => {}}
                        >
                          <div className="flex flex-col flex-1">
                            <LaboratoryOrderListItem labRequest={item} editable={false} readonly />
                            {item?.laboratoryData?.billingType !== BILLING_TYPES_CODES.INSURANCE && (
                              <BillingField
                                hideInsuranceField
                                hideInsuranceOption
                                onBillingChange={(billing) => {
                                  handleSelectCoverage?.(billing, sectionRequestsType, item)
                                }}
                                disabled={isProcessingActions}
                              />
                            )}
                          </div>
                        </Formik>
                      ))}
                  </>
                ) : (
                  <Formik
                    key={sectionRequestsType}
                    initialValues={{
                      billingType:
                        initialBillingType !== BILLING_TYPES_CODES.INSURANCE ? initialBillingType : undefined,
                      insurance: undefined,
                    }}
                    onSubmit={() => {}}
                  >
                    <BillingField
                      hideInsuranceOption
                      onBillingChange={(billing) => {
                        handleSelectCoverage?.(billing, sectionRequestsType)
                      }}
                      disabled={isProcessingActions}
                    />
                  </Formik>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

type Props = {
  label?: string
  shippingMethods?: ChargeItemDefinition[]
  discounts?: ChargeItemDefinition[]
  coverage?: Coverage
  handleSelectCoverage?(billingType: string, type?: ActionGroupCode, item?: CpoeRequest): void
  requests: CpoeRequest[]
  sectionRequestsType: ActionGroupCode
  showShippingMethods: boolean
  showBillingTypes: boolean
  selectedShippingMethod?: ChargeItemDefinition
  handleSelectShippingMethod?(cid: ChargeItemDefinition, type: ActionGroupCode): void
  selectedDiscount?: ChargeItemDefinition
  handleSelectDiscount?(cid: ChargeItemDefinition, type: ActionGroupCode): void
  totalItemsCost?: number
  className?: string
  hasInsuranceLabRequest?: boolean
  labsShippingAddress?: Address
  nutrasShippingAddress?: Address
}

export { CheckoutSection }
